import { Component, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import Swal from 'sweetalert2';
// Mapbox
import { environment } from '../../../environments/environment';
import * as mapboxgl from 'mapbox-gl';
import * as MapboxDirections from '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions';
import { LayerControl, TypeLayers, ControlsWithState } from '../../classes/controls';
import { SelectControl } from 'src/app/classes/controls/selectControl/selectControl';
import { GlobalService } from '../../services/global/global.service';

@Component({
  selector: 'app-mapbox',
  templateUrl: './mapbox.component.html',
  styleUrls: ['./mapbox.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MapboxComponent implements OnInit {

  @Output() coordenate_click: EventEmitter<any>;
  @Output() loaded_map: EventEmitter<any>;
  @Output() asignar: EventEmitter<any>;
  @Output() desasignar: EventEmitter<any>;
  // Variables bases para mapbox
  directions: any;
  mapbox = (mapboxgl as typeof mapboxgl);
  map: mapboxgl.Map;
  container: mapboxgl.Container;
  style = `mapbox://styles/mapbox/light-v10`;
  center = [-58.447040, -34.612918];
  zoom = 10;

  constructor(private globalService: GlobalService) {
    let user = JSON.parse(localStorage.getItem('currentUser'));
    this.mapbox.accessToken = user.mapbox_key;
    this.coordenate_click = new EventEmitter();
    this.loaded_map = new EventEmitter();
    this.asignar = new EventEmitter();
    this.desasignar = new EventEmitter();
  }

  ngOnInit(): void {
    if (this.isWebGLAvailable()) {
      this.buildMap();
    } else {
     this.globalService.showToast("Debe activar la aceleración por hardware para su correcto funcionamiento.",'error')
      // alert('Debe activar la aceleración por hardware para su correcto funcionamiento.')
    }
  }

  public showModal()
  {
    this.map.invalidateSize();
  }
  private isWebGLAvailable(): boolean {
    try {
      const canvas = document.createElement('canvas');
      const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
      return !!gl;
    } catch (e) {
      return false;
    }
  }


  private buildMap() {

    let user = JSON.parse(localStorage.getItem('currentUser'));
    let accessToken = user.mapbox_key;

    this.directions = new MapboxDirections({
      accessToken: accessToken,
      unit: 'metric',
      profile: 'mapbox/driving'
    });

    this.map = new mapboxgl.Map({
      container: 'map',
      style: this.style,
      zoom: this.zoom,
      center: this.center
    });
    this.map.addControl(new mapboxgl.NavigationControl());
    this.map.addControl(new mapboxgl.FullscreenControl());

    this.map.on('click', (e) => {
      let coordinate = e.lngLat.wrap();
      this.coordenate_click.emit([coordinate.lng, coordinate.lat]);
    });

    setTimeout(() => {
      this.loaded_map.emit(false);
    }, 1000)
  }

  public addControlForMonitoring(rutas: any) {
    /* let layerProgramed = new LayerControl(TypeLayers.PROGRAMED, 'programada');
    let layerTraveled = new LayerControl(TypeLayers.TRAVELED, 'realizada'); */
    let selectRuteo = new SelectControl(rutas);

    /* this.map.addControl(layerProgramed,'bottom-left');
    this.map.addControl(layerTraveled,'bottom-left'); */
    this.map.addControl(selectRuteo, 'top-left')

    let controls: ControlsWithState = {select: selectRuteo};
    return controls
  }

  public updateMarker(marker: any, coordinates: any) {
    marker.setLngLat(coordinates);
  }

  public deleteMarker(marker: any) {
    marker.remove();
  }

  public changeCenter(coordinates, varios = true, zoom = 13) {

    switch (varios) {
      case true:
        var bounds = coordinates.reduce(function(bounds, coord) {
          return bounds.extend(coord);
        }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));

        this.map.fitBounds(bounds, {padding: 100});
        break;

      case false:
        this.map.flyTo({
          center: coordinates,
          zoom: zoom,
          bearing: 0,
        });
        break;
    }

  }

  public isValidCoordinates(coordinates) {

    if (!coordinates.match(/^[-]?\d+[\.]?\d*, [-]?\d+[\.]?\d*$/)) {

      return false;
    }

    const [latitude, longitude]=coordinates.split(",");

    return (latitude>-90 && latitude<90 && longitude>-180 && longitude<180);
  }

  public generateMarker(coordinate: Array<string | number>) {

    var marker = new mapboxgl.Marker()
      .setLngLat(coordinate)
      .addTo(this.map);

    return marker;
  }

  public generateMarkerTrack(coordinates: any, date = null, connected = true, width = 32, height = 32) {
    let fechaInicio = null;
    if (!connected) {
      fechaInicio = new Date(date.split(".")[0].replace("T", " ").replaceAll("-", "/")).getTime();
      date = date.split("T");
    }

    let el = document.createElement('div');
    el.className = 'marker';
    el.style.backgroundImage = coordinates.icon;
    el.style.width = width + 'px';
    el.style.height = height + 'px';
    el.style.backgroundSize = 'cover';
    if (!connected) {
      el.addEventListener('click', () =>
        {
          let fechaFin = new Date().getTime();

          let diff = fechaFin - fechaInicio;

          let horas = diff/(1000*60*60);
          let minutos = diff/(1000*60);
          let segundos = diff/(1000);

          let text_diff = ` (lleva ${horas.toFixed(0)} horas, ${minutos.toFixed(0)} minutos y ${segundos.toFixed(0)} segundos desconectado)`;
          let html = `Ultima hora de conexión: <strong>${date[0]} ${date[1].split('.')[0]}</strong>`;
          !connected ? html += text_diff : null;
          this.lastConnection(connected, html);
        }
      );
    }

    let marker = new mapboxgl.Marker(el)
      .setLngLat(coordinates.coords)
      .addTo(this.map);

    return marker;
  }

  public generateCustomMarker(coordinates: any, color) {

    let marker = new mapboxgl.Marker({color: color})
      .setLngLat(coordinates)
      .addTo(this.map);

    return marker;
  }

  public generateCustomMarkerV2(coordinates: any, tipo: string, data_popup: any = null, asignada = null, data_coordenada = null) {

    let marker = null;

    let el = document.createElement('div');
    el.className = 'marker';
    el.style.backgroundImage = 'url(assets/icon/' + tipo + '.png)';
    el.style.backgroundSize = 'cover'
    el.style.width = 25 + 'px';
    el.style.height = 32 + 'px';

    if (asignada != null && data_coordenada != null) {
      el.addEventListener('click', () =>
        {
          asignada === false ? this.asignarEntrega(data_popup, data_coordenada): this.desasignarEntrega(data_popup, data_coordenada);
        }
      );
    }

    marker = new mapboxgl.Marker(el, {offset: [25 / 25, -32 / 2]})
    .setLngLat(coordinates)
    .addTo(this.map);

    return marker;
  }

  public generateCustomMarkerV2Number(coordinates: any, tipo: string, numero: number, color_number: string) {

    let marker = null;

    let el = document.createElement('div');
    el.className = 'marker';
    el.style.backgroundImage = 'url(assets/icon/' + tipo + '.png)';
    el.style.backgroundSize = 'cover'
    el.style.width = 25 + 'px';
    el.style.height = 32 + 'px';
    el.style.textAlign = 'center';
    el.innerHTML = '<div style="color: ' + color_number + ';font-weight: bold;font-size: 14px;padding-top: 3px;">' + numero + '</div>';

    marker = new mapboxgl.Marker(el, {offset: [25 / 25, -32 / 2]})
    .setLngLat(coordinates)
    .addTo(this.map);

    return marker;
  }

  public generateCustomMarkerV3(coordinates: any, tipo: string, class1, class2 = "", data_popup: any = null) {

    let marker = null;

    let el = document.createElement('div');
    el.className = 'marker';
    el.style.backgroundImage = 'url(assets/icon/' + tipo + '.png)';
    el.style.backgroundSize = 'cover'
    el.style.width = 25 + 'px';
    el.style.height = 32 + 'px';
    el.className = `${class1} ${class2}`;

    /* if (asignada != null && data_coordenada != null) {
      el.addEventListener('click', () =>
        {
          asignada === false ? this.asignarEntrega(data_popup, data_coordenada): this.desasignarEntrega(data_popup, data_coordenada);
        }
      );
    } */

    marker = new mapboxgl.Marker(el, {offset: [25 / 25, -32 / 2]})
    .setLngLat(coordinates)
    .addTo(this.map);

    return marker;
  }

  public generateCustomMarkerV3Number(coordinates: any, tipo: string, class1, class2 = "", numero: number, color_number: string, data_popup: any = null) {

    let marker = null;

    let el = document.createElement('div');
    el.className = 'marker';
    el.style.backgroundImage = 'url(assets/icon/' + tipo + '.png)';
    el.style.backgroundSize = 'cover'
    el.style.width = 25 + 'px';
    el.style.height = 32 + 'px';
    el.className = `${class1} ${class2}`;
    el.style.textAlign = 'center';
    el.innerHTML = '<div style="color: ' + color_number + ';font-weight: bold;font-size: 14px;padding-top: 3px;">' + numero + '</div>';

    if (data_popup != null) {
      el.addEventListener('click', () =>
        {
          this.dataEntrega(data_popup);
        }
      );
    }

    marker = new mapboxgl.Marker(el, {offset: [25 / 25, -32 / 2]})
    .setLngLat(coordinates)
    .addTo(this.map);

    return marker;
  }

  public drawRoute(steps) {

    let mapLayer = this.map.getLayer('route');

    if(typeof mapLayer !== 'undefined') {
      this.deleteRoute('route');
    }

    this.addSource('route', steps);

    this.addLayer('route', 4, '#888');
  }

  public drawRouteV2(steps, color, clase) {

    this.addSource(clase, steps);

    return this.addLayer(clase, 4, color);
  }

  public drawLineNna(waypoints) {

    let mapLayer = this.map.getLayer('guide');

    if(typeof mapLayer !== 'undefined') {
      this.deleteRoute('guide');
    }

    this.addSource('guide', waypoints);

    this.addLayer('guide', 1, '#000');
  }

  public hideLayer(layerId, visibility) {
    this.map.setLayoutProperty(layerId, 'visibility', visibility);
  }

  private addSource(type: string, coordinates: Array<string | number>) {
    this.map.addSource(`${type}`, {
      'type': 'geojson',
      'data': {
        'type': 'Feature',
        'properties': {},
        'geometry': {
          'type': 'LineString',
          'coordinates': coordinates
        }
      }
    });
  }

  private addLayer(type: string, width: number, color: string) {
    return this.map.addLayer({
      'id': `${type}`,
      'type': 'line',
      'source': `${type}`,
      'layout': {
        'line-join': 'round',
        'line-cap': 'round',
        'visibility': 'visible'
      },
      'paint': {
        'line-color': `${color}`,
        'line-width': width
      }
    });
  }

  public deleteRoute(type: string) {
    this.map.removeLayer(`${type}`).removeSource(`${type}`);
  }

  private asignarEntrega(data_popup, data_coordenada) {
    Swal
    .fire({
        title: data_popup.title != null ? data_popup.title + ' - ' + data_popup.body : data_popup.body,
        text: "¿Desea asignar esta entrega?",
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Asignar",
    })
    .then(resultado => {
        if (resultado.value) {
          this.asignar.emit(data_coordenada);
        }
    });
  }

  private dataEntrega(data_popup) {
    Swal
    .fire({
        title: data_popup.title,
        html: `
        <ul>
          <li><strong>Id:</strong> ${data_popup.entregaId}</li>
          <li><strong>Dirección:</strong> ${data_popup.direccion}</li>
          <li><strong>Cantidad de bultos:</strong> ${data_popup.cantBultos}</li>
          <li><strong>Datos extras:</strong> ${data_popup.datosExtras != "" ? data_popup.datosExtras : "N/A"}</li>
          <li><strong>Código de seguimiento:</strong> ${data_popup.codigoSeguimiento != "" ? data_popup.codigoSeguimiento : "N/A"}</li>
          <li><strong>Lote:</strong> ${data_popup.lote != null ? data_popup.lote : "N/A"}</li>
          <li><strong>Cliente:</strong> ${data_popup.cliente != null ? data_popup.cliente : "N/A"}</li>
          <li><strong>Estado visita:</strong> ${data_popup.visita.length > 0 ? data_popup.visita[0].estado.nombre : "N/A"}</li>
        </ul>
        `,
        icon: 'info',
    });
  }

  private desasignarEntrega(data_popup, data_coordenada) {
    Swal
    .fire({
        title: data_popup.title != null ? data_popup.title + ' - ' + data_popup.body : data_popup.body,
        text: "¿Desea desasignar esta entrega?",
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Desasignar",
    })
    .then(resultado => {
        if (resultado.value) {
          this.desasignar.emit(data_coordenada);
        }
    });
  }

  private lastConnection(connected, html) {
    Swal
    .fire({
        title: connected ? 'Conectado' : 'Desconectado',
        html: html,
        icon: 'info',
        confirmButtonText: "Ok",
    })
    .then(resultado => {
    });
  }
}
