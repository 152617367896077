import * as mapboxgl from 'mapbox-gl';
import { BehaviorSubject } from 'rxjs';

export abstract class Control {
  protected _map: mapboxgl.Map;
  protected _container: HTMLElement;

  abstract onAdd(map);

  abstract onRemove();
}
