<div class="wrapper">
    <app-navbar></app-navbar>
        <app-main-menu></app-main-menu>
        <div class="content-wrapper">
            <app-content-header></app-content-header>
            <section class="content">
                <div class="container-fluid">
                  <app-loading
                  ></app-loading>
                    <router-outlet></router-outlet>
                </div>
            </section>
        </div>
    <app-footer></app-footer>
</div>
