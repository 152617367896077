import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material.module';
import { NgxLoadingModule } from 'ngx-loading';
// components
import { FooterComponent } from './footer/footer.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ContentHeaderComponent } from './content-header/content-header.component';
import { MapboxComponent } from './mapbox/mapbox.component';
import { LoadingComponent } from './loading/loading.component';

@NgModule({
  declarations: [
    FooterComponent,
    MainMenuComponent,
    NavbarComponent,
    ContentHeaderComponent,
    MapboxComponent,
    LoadingComponent
  ],
  exports: [
    FooterComponent,
    MainMenuComponent,
    NavbarComponent,
    ContentHeaderComponent,
    MapboxComponent,
    LoadingComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    NgxLoadingModule.forRoot({})
  ]
})
export class ComponentsModule { }
