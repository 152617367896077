import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { Subject } from 'rxjs';
import { message_error } from '../../config/constantes';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  public fooSubject = new Subject<any>();
  constructor(private _http: HttpClient) { }

  showToast(description:string, icon:any) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      customClass: {
        container: 'swal2-toast-custom'
      },
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    Toast.fire({
      icon: icon,
      title: description
    })
  }

  showError(err:any) {
    console.error(err);
    if (typeof err == 'string') {
      err = JSON.parse(err);
    }
    if (typeof err.error != 'undefined') {
      if (err.error.error_controlado) {
        this.showToast(err.error.error_controlado, "error");
      }
    } else if ( typeof err.error_controlado != 'undefined') {
      this.showToast(err.error_controlado, "error");
    }else {
      this.showToast(message_error, "error");
    }
  }

  clearSesion() {
    localStorage.removeItem('currentUser');
  }

  publishSomeData(data: any) {
    this.fooSubject.next(data);
  }

  getObservable(): Subject<any> {
    return this.fooSubject;
  }

  getMenuOpts() {
    return this._http.get<any[]>('/assets/data/menu.json');
  }

}
