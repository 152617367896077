import { BehaviorSubject } from 'rxjs';
import { State } from '..';
import { Control } from "../abstracts/control";

export class SelectControl extends Control implements State {

  private _state: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _id: string;
  private _rutas: any;

  public constructor(rutas: any) {
    super();
    this._id = 'select-ruteo-id';
    this._rutas = rutas;
  }

  onAdd(map) {
    this._map = map;
    this._container = document.createElement('div');
    this._container.className = 'selected-route';
    this._container.setAttribute("id", this._id);

    this.makeCheckbox();

    return this._container;
  }

  private makeCheckbox() {
    for (let ruta of this._rutas) {
      var containerRuteoCheckbox = document.createElement('div');
      containerRuteoCheckbox.className = 'container-ruteo-checkbox';
      containerRuteoCheckbox.setAttribute("id", 'container-ruteo-checkbox-' + ruta.id);

      var ruteoCheckbox = document.createElement('input');
      ruteoCheckbox.type = 'checkbox';
      ruteoCheckbox.className = 'ruteo-checkbox';
      ruteoCheckbox.id = 'select-ruteo-check-' + ruta.id; // need unique Ids!
      ruteoCheckbox.value = ruta.id + '';
      ruteoCheckbox.checked = true;

      var label = document.createElement('label');
      label.textContent = `${ruta.id}-${ruta.movil}-${ruta.fecha}`;
      label.className = 'ruteo-checkbox-label';
      label.setAttribute('for', 'select-ruteo-check-' + ruta.id);
      label.setAttribute("style", "font-weight: bold;");

      containerRuteoCheckbox.appendChild(ruteoCheckbox);
      containerRuteoCheckbox.appendChild(label);

      ruteoCheckbox.addEventListener('change', (element: any) => {
        this._state.next({id: element.target.value, checked: element.target.checked});
        // e.preventDefault()
        element.stopPropagation()
      }, false);

      this._container.appendChild(containerRuteoCheckbox);
    }
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }

  get state(): BehaviorSubject<number> {
    return this._state;
  }

}
