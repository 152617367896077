import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  
  constructor( private _authService: AuthService,
               private route: Router ) {

  }

  canActivate():boolean {
    if (this._authService.autenticated()) {
      return true;
    } else {
      this.route.navigateByUrl('/auth/login');
      return false;
    }
  }
  
}
