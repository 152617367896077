import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { CustomRequest } from '../custom.request';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TerminosService {

  apiUrl = environment.apiUrl + 'terminos/';

  constructor(private _http: HttpClient) { }

  // get all users
  getTermino(): Observable<any> {
    return this._http.get(this.apiUrl + 'get-termino', CustomRequest.jwt())
    .pipe( map( (response:any) => response.data ));
  }

  // get all users
  cumpleTerminosYCondiciones(usuario_id): Observable<any> {
    return this._http.get(this.apiUrl + 'get-termino-bool/' + usuario_id, CustomRequest.jwt())
    .pipe( map( (response:any) => response.data ));
  }
  
  aceptar(usuario_id, termino_id) {
    return this._http.post(this.apiUrl + 'aceptar/' + usuario_id + '/' + termino_id , CustomRequest.jwt())
    .pipe( map( (response:any) => response.data ));
  }
}
